<template>
  <v-content>
    <v-container fluid grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12>
          <AppTitle>
            <template slot="title"
              >Solicitudes de insumos
            </template>
            <template slot="subtitle">
              Todas las solicitudes pendientes de entrega</template
            >
            <template slot="button">
              <v-tooltip bottom>
                <v-btn
                  slot="activator"
                  :disabled="loading"
                  :loading="loading"
                  flat
                  icon
                  class="mt-0 mx-1"
                  @click="getListInsumos"
                >
                  <v-icon>autorenew</v-icon>
                </v-btn>
                <span>Actualizar</span>
              </v-tooltip>
            </template>
          </AppTitle>
          <template v-if="$mq !== 'sm' && $mq !== 'xs'">
            <v-card>
              <AppTitleCard>
                <template slot="title">
                  En total
                  <strong class="secondary--text"> {{ items.length }}</strong>
                  solicitudes
                </template>
              </AppTitleCard>
              <v-divider></v-divider>
              <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-0 order-table"
                color="secondary"
                :disable-initial-sort="true"
                :pagination.sync="pagination"
                :rows-per-page-items="rowsPerPage"
                rows-per-page-text="Pedidos por pagina"
                no-data-text="No hay solicitudes"
                :loading="loading"
              >
                <template slot="items" slot-scope="props">
                  <tr>
                    <td>{{ props.item.id }}</td>
                    <td>{{ props.item.date_shipping }}</td>
                    <td>{{ props.item.store.name }}</td>
                    <td class="text-xs-right">
                      {{ total(props.item.insumos) | toPrice }}
                    </td>
                    <td v-if="user.shop.admin" class="text-xs-right">
                      <v-btn
                        v-if="props.item.status === 0"
                        class="mx-0"
                        color="error"
                        small
                        @click="changeStatus(props.item, 1)"
                      >
                        Recibir
                      </v-btn>
                      <v-btn
                        v-else
                        class="mx-0"
                        color="success"
                        small
                        @click="editInsumo(props.item)"
                      >
                        Revisar
                      </v-btn>
                    </td>
                    <td v-else-if="!user.shop.admin">
                      <v-btn
                        v-if="props.item.status === 0"
                        class="mx-0"
                        color="info"
                        small
                        @click="editItem(props.item)"
                      >
                        Editar
                      </v-btn>
                      <v-btn
                        v-else
                        class="mx-0"
                        color="success"
                        small
                        @click="editInsumo(props.item)"
                      >
                        Recibido
                      </v-btn>
                    </td>
                  </tr>
                </template>
                <template slot="pageText" slot-scope="props">
                  Pagina {{ props.pageStart }} de {{ props.itemsLength }}
                </template>
              </v-data-table>
            </v-card>
          </template>
          <template v-else>
            <v-data-iterator
              :items="items"
              :rows-per-page-items="rowsPerPage"
              :pagination.sync="pagination"
              content-tag="v-layout"
              no-data-text="No hay solicitudes"
              row
              wrap
            >
              <v-flex slot="item" slot-scope="props" xs12 sm4 md4 lg3>
                <v-card>
                  <v-card-title>
                    <h4 class="title font-weight-regular">
                      Solicitud <strong>{{ props.item.id }}</strong>
                    </h4>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-tile>
                      <v-list-tile-content
                        >Fecha de retiro:</v-list-tile-content
                      >
                      <v-list-tile-content class="align-end text-xs-right">
                        {{ props.item.date_shipping }}
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile>
                      <v-list-tile-content>Tienda</v-list-tile-content>
                      <v-list-tile-content class="align-end text-xs-right">
                        {{ props.item.store.name }}
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile class="px-0">
                      <v-spacer></v-spacer>
                      <template v-if="user.shop.admin">
                        <v-btn
                          v-if="props.item.status === 0"
                          class="mx-0"
                          color="error"
                          small
                          @click="changeStatus(props.item, 1)"
                        >
                          Recibir
                        </v-btn>
                        <v-btn
                          v-else
                          class="mx-0"
                          color="warning"
                          small
                          @click="editInsumo(props.item)"
                        >
                          Revisar
                        </v-btn>
                      </template>
                      <template v-else-if="!user.shop.admin">
                        <v-btn
                          v-if="props.item.status === 0"
                          class="mx-0"
                          color="info"
                          small
                          @click="editItem(props.item)"
                        >
                          Editar
                        </v-btn>
                        <v-btn
                          v-else
                          class="mx-0"
                          color="warning"
                          small
                          @click="editItem(props.item)"
                        >
                          Recibido
                        </v-btn>
                      </template>
                    </v-list-tile>
                  </v-list>
                </v-card>
              </v-flex>
            </v-data-iterator>
          </template>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      v-model="dialogForm"
      content-class="dialogForm"
      max-width="1000"
      scrollable
      persistent
    >
      <v-card>
        <TitleDialog
          :title="`Solicitud # ${item.id}`"
          subtitle="Revisar stock y enviar solicitud"
          :close-action="
            () => {
              dialogForm = false
            }
          "
          close
        >
          <template slot="icons">
            <v-tooltip bottom>
              <v-btn slot="activator" flat icon @click="printWindow">
                <v-icon>print</v-icon>
              </v-btn>
              <span>Actualizar</span>
            </v-tooltip>
          </template>
        </TitleDialog>
        <v-card-text style="height: 600px;">
          <v-container class="pa-2" grid-list-lg>
            <v-layout wrap>
              <v-flex xs12>
                <v-badge color="red">
                  <template v-slot:badge>
                    <span>{{ item.histories.length }}</span>
                  </template>
                  <v-btn
                    color="link"
                    class="ma-0"
                    small
                    @click="showHistories = !showHistories"
                  >
                    <span v-if="!showHistories">
                      Ver historial de comentarios</span
                    >
                    <span v-else> Ocultar historial de comentarios</span>
                  </v-btn>
                </v-badge>
              </v-flex>
              <v-flex
                v-if="item.histories !== undefined && showHistories"
                xs12
                class="d-print-none"
              >
                <h2 class="title">Historial comentarios</h2>
                <v-timeline align-top dense>
                  <template v-for="(history, index) in item.histories">
                    <v-timeline-item
                      :key="index"
                      :color="history.admin ? 'primary' : 'accent'"
                      small
                    >
                      <v-layout pt-3>
                        <v-flex xs3>
                          <strong>{{
                            $moment
                              .unix(history.date_in)
                              .format('DD-MM-YYYY HH:mm:ss')
                          }}</strong>
                        </v-flex>
                        <v-flex>
                          <strong>{{
                            history.admin
                              ? 'Floreria Rosalinda'
                              : item.store.name
                          }}</strong>
                          <div class="caption" v-html="history.message"></div>
                        </v-flex>
                      </v-layout>
                    </v-timeline-item>
                  </template>
                </v-timeline>
              </v-flex>
              <v-flex v-if="item.user !== undefined" xs4>
                <v-text-field
                  v-model="item.user.name"
                  label="Usuario"
                  hide-details
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex v-if="item.store !== undefined" xs4>
                <v-text-field
                  v-model="item.store.name"
                  label="Tienda"
                  hide-details
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex v-if="item.date_shipping !== undefined" xs4>
                <v-text-field
                  v-model="item.date_shipping"
                  type="date"
                  label="Fecha de retiro solicitada"
                  hide-details
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex v-if="user.shop.admin" xs12>
                <v-layout wrap>
                  <v-flex xs12 class="pa-0">
                    <v-subheader>
                      Agregar Insumo
                    </v-subheader>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      v-model="quantity"
                      label="Cantidad"
                      type="number"
                      :suffix="
                        `${this.insumoSelected ? this.insumoSelected.umc : ''}`
                      "
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-autocomplete
                      v-model="insumoSelected"
                      :items="insumos"
                      :search-input.sync="search"
                      hide-no-data
                      hide-details
                      hide-selected
                      :item-text="e => `${e.type} ${e.name}`"
                      item-value="id"
                      label="Insumos"
                      prepend-icon="search"
                      return-object
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-tile-avatar v-if="item.photo !== ''">
                          <v-img
                            :src="item.photo"
                            :lazy-src="item.photo"
                            aspect-ratio="1"
                            class="grey lighten-2"
                          >
                          </v-img>
                        </v-list-tile-avatar>
                        <v-list-tile-avatar v-else>
                          <v-avatar size="38" color="primary">
                            <v-icon dark>contact_support</v-icon>
                          </v-avatar>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title
                            v-html="`${item.type} | ${item.name}`"
                          ></v-list-tile-title>
                          <v-list-tile-sub-title
                            v-html="item.codigo"
                          ></v-list-tile-sub-title>
                        </v-list-tile-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs3>
                    <v-btn
                      class="mt-3"
                      color="accent"
                      depressed
                      block
                      @click="addInsumoItem"
                    >
                      Agregar
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
              </v-flex>
              <v-flex v-if="item.insumos !== undefined" xs12>
                <template v-if="user.shop.admin">
                  <v-data-table
                    :headers="[
                      { text: '', align: 'left', sortable: false },
                      { text: 'Foto', align: 'left', sortable: false },
                      { text: 'Cantidad', align: 'left', sortable: false },
                      { text: 'Entregado', align: 'left', sortable: false },
                      { text: 'Nombre', align: 'left', sortable: false },
                      { text: 'Valor', align: 'left', sortable: false },
                      { text: 'Total', align: 'right', sortable: false }
                    ]"
                    :items="item.insumos"
                    hide-actions
                    class="elevation-0"
                  >
                    <template slot="headers" slot-scope="props">
                      <tr>
                        <th
                          v-for="header in props.headers"
                          :key="header.text"
                          :class="
                            `text-xs-${header.align} ${
                              header.text === 'Valor' ? 'd-print-none' : ''
                            }`
                          "
                        >
                          {{ header.text }}
                        </th>
                      </tr>
                    </template>
                    <template slot="items" slot-scope="props">
                      <td class="text-xs-left">
                        <v-btn small icon flat>
                          <v-icon small @click="deleteItemAdmin(props.item)">
                            delete
                          </v-icon>
                        </v-btn>
                      </td>
                      <td class="text-xs-left">
                        <v-avatar
                          v-if="props.item.photo"
                          :size="40"
                          color="grey lighten-4 mb-3"
                          style="float: left;margin-right: 20px;margin-top: 4px;"
                        >
                          <img :src="props.item.photo" alt="avatar" />
                        </v-avatar>
                      </td>
                      <td class="text-xs-left">
                        {{ props.item.quantity_original }}
                      </td>
                      <td class="text-xs-left">
                        <v-text-field
                          v-model="props.item.quantity_available"
                          type="number"
                          label="Cantidad entregada"
                          hide-details
                          :max="props.item.quantity_available"
                          min="0"
                          :suffix="props.item.umc"
                          :disabled="props.item.active"
                          style="width: 160px;"
                        ></v-text-field>
                      </td>
                      <td class="text-xs-left">
                        {{ props.item.name }}
                      </td>
                      <td
                        class="text-xs-left py-2 d-print-none"
                        style="width: 160px;"
                      >
                        <v-text-field
                          v-model="props.item.price"
                          prefix="$"
                          type="number"
                          label="Valor unitario"
                          hide-details
                          :disabled="props.item.active"
                          min="0"
                          style="width: 160px;"
                        ></v-text-field>
                      </td>
                      <td
                        class="text-xs-right py-2 d-print-none"
                        style="width: 160px;"
                      >
                        <span class="body-2">{{
                          (props.item.price * props.item.quantity_available)
                            | toPrice
                        }}</span>
                      </td>
                    </template>
                    <template slot="footer">
                      <td colspan="4" class="text-xs-right">
                        <strong>Total</strong>
                      </td>
                      <td class="text-xs-right" colspan="1">
                        <strong>{{
                          _.sumBy(item.insumos, o =>
                            parseInt(o.quantity_available * o.price, 10)
                          ) | toPrice
                        }}</strong>
                      </td>
                    </template>
                  </v-data-table>
                </template>
                <template v-else>
                  <v-data-table
                    :headers="[
                      { text: 'Nº', align: 'left', sortable: false },
                      { text: 'Cantidad', align: 'left', sortable: false },
                      { text: 'Disponible', align: 'left', sortable: false },
                      { text: 'Nombre', align: 'left', sortable: false },
                      { text: 'Valor', align: 'right', sortable: false },
                      { text: 'Total', align: 'right', sortable: false }
                    ]"
                    :items="item.insumos"
                    hide-actions
                    class="elevation-0"
                  >
                    <template slot="headers" slot-scope="props">
                      <tr>
                        <th
                          v-for="header in props.headers"
                          :key="header.text"
                          :class="
                            `text-xs-${header.align} ${
                              header.text === 'Valor' ? 'd-print-none' : ''
                            }`
                          "
                        >
                          {{ header.text }}
                        </th>
                      </tr>
                    </template>
                    <template slot="items" slot-scope="props">
                      <td class="text-xs-right">{{ props.index + 1 }}</td>
                      <td
                        class="text-xs-right"
                        v-html="props.item.quantity"
                      ></td>
                      <td
                        class="text-xs-right"
                        v-html="props.item.quantity_available"
                      ></td>
                      <td class="text-xs-left" v-html="props.item.name"></td>
                      <td class="text-xs-right">
                        {{ props.item.value | toPrice }}
                      </td>
                      <td class="text-xs-right">
                        {{
                          (props.item.quantity_available * props.item.value)
                            | toPrice
                        }}
                      </td>
                    </template>
                    <template slot="footer">
                      <td colspan="4" class="text-xs-right">
                        <strong>Total</strong>
                      </td>
                      <td class="text-xs-right" colspan="1">
                        <strong>{{
                          _.sumBy(item.insumos, 'total') | toPrice
                        }}</strong>
                      </td>
                      <td colspan="1">
                        <strong></strong>
                      </td>
                    </template>
                  </v-data-table>
                </template>
                <v-divider class="d-print-none"></v-divider>
              </v-flex>
              <v-flex xs12 class="d-print-none">
                <v-textarea
                  v-model="item.message"
                  box
                  rows="2"
                  :label="'Comentarios adicionales'"
                  placeholder=""
                  hint="No es obligatorio :)"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider class="d-print-none"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="success" depressed @click="problemOut">
            Actualizar 1
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogItem" max-width="900px" persistent>
      <v-card>
        <TitleDialog
          :title="!form.id ? 'Solicitar insumos' : `Solicitud #${form.id}`"
          :close-action="() => (dialogItem = false)"
          close
        >
        </TitleDialog>
        <v-container class="pa-3" grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12>
              <v-alert
                v-if="form.status === 0 && form.id"
                :value="true"
                color="info"
              >
                Puede editar la solicitud porque aun no es recibida
              </v-alert>
              <v-alert v-if="form.status === 1" :value="true" color="warning">
                Verificando existencias de insumos solicitados
              </v-alert>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="form.date_shipping"
                type="date"
                label="Fecha de retiro"
                :disabled="form.status !== 0"
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex v-if="form.status === 0" xs3>
              <v-text-field
                v-model="quantity"
                label="Cantidad"
                type="number"
                :suffix="
                  `${this.insumoSelected ? this.insumoSelected.umc : ''}`
                "
              ></v-text-field>
            </v-flex>
            <v-flex v-if="form.status === 0" xs6>
              <v-autocomplete
                v-model="insumoSelected"
                :items="insumos"
                hide-no-data
                hide-selected
                :item-text="e => `${e.type} ${e.name}`"
                item-value="id"
                label="Insumos"
                prepend-icon="search"
                return-object
              >
                <template slot="item" slot-scope="{ item }">
                  <v-list-tile-avatar v-if="item.photo !== ''">
                    <v-img
                      :src="item.photo"
                      :lazy-src="item.photo"
                      aspect-ratio="1"
                      class="grey lighten-2"
                    >
                    </v-img>
                  </v-list-tile-avatar>
                  <v-list-tile-avatar v-else>
                    <v-avatar size="38" color="primary">
                      <v-icon dark>contact_support</v-icon>
                    </v-avatar>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title
                      v-html="`${item.type} | ${item.name}`"
                    ></v-list-tile-title>
                    <v-list-tile-sub-title
                      v-html="item.codigo"
                    ></v-list-tile-sub-title>
                  </v-list-tile-content>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex v-if="form.status === 0" xs3>
              <v-btn
                class="mt-3"
                color="accent"
                depressed
                block
                @click="addInsumo"
              >
                Agregar
              </v-btn>
            </v-flex>
            <v-flex xs12>
              <v-data-table
                :headers="[
                  { text: 'Nº', align: 'left', sortable: false },
                  { text: 'Cantidad', align: 'left', sortable: false },
                  { text: 'Nombre', align: 'left', sortable: false },
                  { text: 'Precio Unitario', align: 'right', sortable: false },
                  { text: 'Precio Total', align: 'right', sortable: false },
                  { text: '', align: 'right', sortable: false }
                ]"
                :items="form.insumos"
                no-data-text="Ingrese un insumo..."
                class="elevation-0"
                hide-actions
              >
                <template slot="items" slot-scope="props">
                  <td>
                    {{ props.index + 1 }}
                  </td>
                  <td class="text-xs-left">
                    <v-edit-dialog
                      v-if="form.status === 0"
                      :return-value.sync="props.item.quantity"
                      lazy
                    >
                      {{ props.item.quantity }} {{ props.item.umc }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.quantity"
                          label="Cantidad"
                          :suffix="props.item.umc"
                          single-line
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                    <span v-else>
                      {{ props.item.quantity }}
                    </span>
                  </td>
                  <td class="text-xs-left">
                    <v-avatar
                      v-if="props.item.photo"
                      :size="40"
                      color="grey lighten-4 mb-3"
                      style="float: left;margin-right: 20px;margin-top: 4px;"
                    >
                      <img :src="props.item.photo" alt="avatar" />
                    </v-avatar>
                    <v-edit-dialog
                      v-if="form.status === 0"
                      :return-value.sync="props.item.name"
                      lazy
                    >
                      {{ props.item.name }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.name"
                          label="Nombre"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                    <span v-else>
                      {{ props.item.name }}
                    </span>
                  </td>
                  <td class="text-xs-right">
                    {{ props.item.price | toPrice }}
                  </td>
                  <td class="text-xs-right">
                    {{ (props.item.price * props.item.quantity) | toPrice }}
                  </td>
                  <td v-if="form.status === 0" class="text-xs-right">
                    <v-icon small class="mr-2" @click="deleteItem(props.item)">
                      delete
                    </v-icon>
                  </td>
                </template>
                <template slot="footer">
                  <td colspan="4" class="text-xs-right">
                    <strong>Total</strong>
                  </td>
                  <td class="text-xs-right" colspan="1">
                    <strong>{{
                      _.sumBy(form.insumos, o =>
                        parseInt(o.quantity * o.price, 10)
                      ) | toPrice
                    }}</strong>
                  </td>
                  <td colspan="1">
                    <strong></strong>
                  </td>
                </template>
              </v-data-table>
            </v-flex>
            <v-flex xs12>
              <v-divider class="mb-4"></v-divider>
              <v-textarea
                v-model="form.message"
                box
                rows="2"
                :label="'Comentarios adicionales'"
                placeholder=""
                hint="No es obligatorio :)"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialogItem = false">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="form.status === 0" color="accent" flat @click="saveForm">
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      v-if="!user.shop.admin"
      dark
      fixed
      bottom
      right
      color="accent"
      @click="editItem({})"
    >
      <v-icon left>add</v-icon>
      Solicitar insumos
    </v-btn>
  </v-content>
</template>

<script>
import { GET_INSUMOS } from '../config'
import AppTitle from './useful/title.vue'
import AppTitleCard from './useful/titleCard.vue'
import TitleDialog from './useful/titleDialog.vue'

export default {
  name: 'SolicitudInsumos',
  metaInfo: {
    title: 'Solicitud de insumos'
  },
  components: {
    AppTitle,
    AppTitleCard,
    TitleDialog
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    nameStatus() {
      const states = this.$store.getters.getStates
      const general = this.$store.getters.getGeneral
      const statusActive = this._.filter(states, ['id', general.status])
      if (statusActive[0] !== undefined) {
        return statusActive[0].name
      }
      return ''
    },
    general() {
      return this.$store.getters.getGeneral
    },
    onlyProducts() {
      const products = []
      this._.forEach(this.orders, order => {
        this._.forEach(order.products, product => {
          products.push(product)
        })
      })
      return products
    },
    porElaborar() {
      return this._.filter(this.onlyProducts, ['status', 0])
    },
    elaborados() {
      return this._.filter(this.onlyProducts, ['status', 1])
    }
  },
  methods: {
    async editItem(item) {
      try {
        const res = await this.$http(GET_INSUMOS)
        this.insumos = res.data
        this.form = {
          insumos: [],
          date_in: null,
          date_shipping: null,
          status: 0
        }
        if (this._.size(item) > 0) {
          this.form = item
        }
        this.dialogItem = true
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async changeStatus(item, status) {
      try {
        await this.$http.put(`${GET_INSUMOS}/solicitudes`, {
          status,
          id: item.id,
          item
        })
        this.getListInsumos()
        item.status = status
        this.dialogForm = false
        this.editDialog = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async editInsumo(item) {
      this.dialogForm = true
      this.item = item
      try {
        const res = await this.$http(GET_INSUMOS)
        this.insumos = res.data
        this.dialogForm = true
        this.item = item
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getListInsumos() {
      this.loading = true
      try {
        const res = await this.$http.get(
          `${GET_INSUMOS}/solicitudes?${this.$qs.stringify({
            status: 0,
            admin: this.user.shop.admin
          })}`
        )
        this.items = res.data
        this.itemsAll = res.data
        this.loading = false
        this.dialogFilter = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async saveForm() {
      if (this.form.date_shipping !== null && this.form.insumos.length > 0) {
        try {
          await this.$http.post(`${GET_INSUMOS}/solicitudes`, {
            form: this.form
          })
          this.dialogItem = false
          this.form.date_in = null
          this.form.insumos = []
          this.getListInsumos()
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      } else {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Debe completar todos los campos'
        })
      }
    },
    total(insumos) {
      return this._.sumBy(insumos, o =>
        parseInt(o.quantity_available * o.price, 10)
      )
    },
    deleteItem(index) {
      for (let i = 0; i < this.form.insumos.length; i += 1) {
        if (this.form.insumos[i] === index) {
          this.form.insumos.splice(i, 1)
          i -= 1
        }
      }
    },
    deleteItemAdmin(index) {
      for (let i = 0; i < this.item.insumos.length; i += 1) {
        if (this.item.insumos[i] === index) {
          this.item.insumos.splice(i, 1)
          i -= 1
        }
      }
    },
    addInsumo() {
      if (this.insumoSelected) {
        const item = {
          insumoID: this.insumoSelected.id,
          name: `${this.insumoSelected.codigo} - ${this.insumoSelected.name}`,
          quantity: this.quantity,
          price: this.insumoSelected.price,
          photo: this.insumoSelected.photo,
          priceCost: this.insumoSelected.priceCost,
          umc: this.insumoSelected.umc
        }
        this.form.insumos.push(item)
        this.quantity = 1
        this.insumoSelected = null
      }
    },
    addInsumoItem() {
      if (this.insumoSelected) {
        const item = {
          insumoID: this.insumoSelected.id,
          name: `${this.insumoSelected.codigo} - ${this.insumoSelected.name}`,
          quantity: this.quantity,
          quantity_original: this.quantity,
          quantity_available: this.quantity,
          photo: this.insumoSelected.photo,
          price: this.insumoSelected.price,
          value: this.insumoSelected.price,
          priceCost: this.insumoSelected.priceCost,
          total: this.insumoSelected.price * this.quantity,
          date_ready: null
        }
        this.item.insumos.push(item)
        this.quantity = 1
        this.insumoSelected = null
      }
    },
    printWindow() {
      window.print()
    },
    changeShow() {
      if (!this.diseno) {
        this.$router.push({
          name: 'pedidosDiseno',
          params: { diseno: 'vista-diseno' }
        })
      } else {
        this.$router.push({ name: 'pedidos' })
      }
    },
    status(e) {
      if (e) {
        return 'success'
      }
      return 'error'
    },
    goOrder(nameRoute, id) {
      this.$router.push({ name: nameRoute, params: { id } })
    },
    searchInputHandle: _.debounce(function debounce() {
      const search = this.inputSearch

      const result = this._.filter(this.ordersAll, item => {
        let res = this._.includes(
          this._.lowerCase(item.client),
          this._.lowerCase(search)
        )
        if (!res) {
          res = this._.includes(
            this._.lowerCase(item.paymentMethod),
            this._.lowerCase(search)
          )
          if (!res) {
            res = this._.includes(
              this._.lowerCase(item.schedule),
              this._.lowerCase(search)
            )
            if (!res) {
              res = this._.includes(
                this._.lowerCase(item.id),
                this._.lowerCase(search)
              )
              if (!res) {
                res = this._.includes(
                  this._.lowerCase(item.total),
                  this._.lowerCase(search)
                )
              }
            }
          }
        }
        return res
      })

      if (result.length > 0) {
        this.orders = result
      } else {
        this.orders = []
      }
    }, 500),
    problemOut() {
      this.changeStatus(this.item, 3)
    }
  },
  data() {
    return {
      insumos: [],
      form: {
        insumos: [],
        date_in: null,
        date_shipping: null,
        status: 0,
        message: null
      },
      quantity: 1,
      name: null,
      insumoSelected: null,
      stats: {},
      dialogFilter: false,
      dialogItem: false,
      menuDate: false,
      loading: false,
      dialogForm: false,
      editDialog: false,
      showHistories: false,
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      item: {},
      items: [],
      itemsAll: [],
      headers: [
        { text: '#', align: 'left', sortable: false },
        { text: 'Fecha retiro', align: 'left', sortable: false },
        { text: 'Tienda', align: 'left', sortable: false },
        { text: 'Total', align: 'right', sortable: false },
        { text: '', sortable: false }
      ],
      inputSearch: null
    }
  },
  mounted() {
    this.getListInsumos()
  }
}
</script>

<style>
@media print {
  .dialogForm {
    max-height: fit-content;
    box-shadow: none;
    width: 1000px;
    margin: 0px;
    max-width: 100% !important;
  }
  .dialogForm .v-card__actions {
    display: none !important;
  }
  .v-overlay {
    display: none;
  }
  .application--wrap {
    display: none;
  }
  .v-dialog__content {
    display: block;
    height: 100%;
    position: relative;
    top: 0;
    width: 100%;
  }
}
</style>
